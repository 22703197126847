// import { TextField } from "@mui/material";
// import { FieldInputProps, FormikProps } from "formik";
// import { toast } from "react-toastify";

// interface IUploadImageProps {
//   field: FieldInputProps<string>;
//   form: FormikProps<string>;
// }

// export const UploadImage = ({ field, form, ...props }: IUploadImageProps) => {
//   const { setFieldValue } = form;
//   const handleChangeImg = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     const target = e.target as HTMLInputElement;
//     const file = target && target.files ? target.files[0] || null : null;
//     console.log("target ::", file);
//     if (!file) return;
//     if (
//       file &&
//       file.size &&
//       Number((file.size / (1024 * 1024)).toFixed(2)) > 2
//     ) {
//       toast.warn("File size is too large! Image should be less than 2 MB");
//       setFieldValue(field.name, null); // Use field.name to clear the specific field value
//       return; // This ensures the function exits
//     }    
//     else {
//       setFieldValue(field.name, file);
//     }
//   };

//   return (
//     <>
//       <TextField
//         // {...field}
//         onChange={(e) => handleChangeImg(e)}
//         {...props}
//       />
//     </>
//   );
// };


import { TextField } from "@mui/material";
import { FieldInputProps, FormikProps } from "formik";
import { toast } from "react-toastify";

interface IUploadImageProps {
  field: FieldInputProps<string>;
  form: FormikProps<any>;
}

export const UploadImage = ({ field, form, ...props }: IUploadImageProps) => {
  const { setFieldValue } = form;

  const handleChangeImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target?.files?.[0] || null;

    if (!file) return;

    const fileSizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    if (fileSizeInMB > 2) {
      toast.warn("File size is too large! Image should be less than 2 MB.");
      // Reset the input value to avoid invalid file selection
      target.value = "";
      return;
    }

    // Only update Formik field value if size check passes
    setFieldValue(field.name, file);
  };

  return (
    <TextField
      type="file"
      onChange={handleChangeImg}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
};
