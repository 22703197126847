import { useState } from "react";
import { IContent, IProductList } from "../types/configService";
import {
  Box,
  Collapse,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

// icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

// component
import EnhancedTableHead from "../../../../../../components/Mui/table/Table";
import { headCellsServiceCategoryConfig } from "../../ServiceCategory/serviceCategoryConstant";

interface IServiceTableCell {
  row: IContent;
  index: number;
  labelId: string;
  handleChangeSwitch: (
    event: React.ChangeEvent<HTMLInputElement>,
    row: IContent
  ) => void;
  handleOpenDialogueEditService: (row: IContent) => void;
  handleDeleteService: (row: IContent) => void;
}

export const ServiceTableCell = ({
  row,
  labelId,
  handleChangeSwitch,
  handleOpenDialogueEditService,
  handleDeleteService,
}: IServiceTableCell) => {
  const [openTableRow, setOpenTableRow] = useState(false);

  const [selected] = useState([]);
  const serviceCategory = row?.serviceCategory;

  return (
    <>
    {/* {console.log(row)} */}
      <TableRow
        sx={{
          bgcolor: row.status === "DELETED" ? "gray" : "white",
          cursor: "pointer",
          mx: 4,
        }}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenTableRow((prev) => !prev)}
          >
            {openTableRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.serviceName}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.avgServeTime}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.gender}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.price}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          <Switch
            checked={row.status == "INACTIVE" ? false : true}
            onChange={(event) => handleChangeSwitch(event, row)}
            // onChange={(event) => console.log(event.target.value, row)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          <ModeEditIcon
            sx={{ mx: 2 }}
            onClick={() => handleOpenDialogueEditService(row)}
          />
          { /* <DeleteIcon
            color="error"
            sx={{ mx: 2 }}
            onClick={() => handleDeleteService(row)}
          /> */ }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openTableRow} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Service Category Details
              </Typography>
              <Table size="small">
                <EnhancedTableHead
                  numSelected={selected.length}
                  headCells={headCellsServiceCategoryConfig}
                />

                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {serviceCategory.categoryName}
                    </TableCell>
                    <TableCell align="left">{serviceCategory.gender}</TableCell>
                    <TableCell align="left">{serviceCategory.status}</TableCell>
                    <TableCell align="left">
                      {serviceCategory.description}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
