import React from "react";
import { handleTimeConvertion } from "../../../helper/Common/DateFunctions";

function Ticket({ data, waitingTime }) {

  return (
    <>
      <div className="w-full h-full sm:w-5/6 sm:h-4/5 lg:w-[200px] bg-red-600 p-3">
        <div className="border-2 border-white w-full h-full flex flex-center overflow-hidden p-2">
          <p className="z-20 text-xl text-wrap font-bold text-white">
            {data ? data : ""}
          </p>
          <span className="bottom-0 py-2 left-0 w-full text-center text-xl text-white border-2 border-white">
            Ticket No.
          </span>
        </div>
      </div>
      <div className="flex justify-center items-center m-4 w-full sm:h-20">
      <p className="text-center text-xl font-semibold"> Waiting Time: {handleTimeConvertion(waitingTime)}</p>
      </div>

    </>
  );
}

export default Ticket;
