import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../helper/Common/constant";
import { FieldIcon } from "../../../components/Mui/Formik/FieldIcon";
import { SalonLogo } from "../../../assets/img";
import { useNavigate } from "react-router-dom";
import { PhoneAndroid } from "@mui/icons-material";
import { loginEmployee } from "../../../api/salon-api/auth-service";

import KeyIcon from "@mui/icons-material/Key";
import { setAttendance } from "../../../redux/feature/attendanceSlice";
import { useDispatch, useSelector } from "react-redux";

export default function EmployeeLogin() {
  const navigate = useNavigate();
  const attendance = useSelector((state) => state => state.attendance);
  const uploadLogo = useSelector((state) => state.UploadLogo?.logo);
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigate("/customer/signup");
  };

  // const adminLogin = async (data) => {
  //   const { number, password } = data;
  //   if (number && password) {

  //     const payload = {
  //       mobileNo: number,
  //       password
  //     }
  //     try {
  //       const resp = await loginEmployee(payload);
  //       if (resp.staffId) {
  //         toast("Successfully Logged in!");
  //         window.localStorage.setItem("staffId", `${resp.staffId}`);
  //         console.log("resp ::", resp.staffId);
  //         dispatch(setAttendance(resp));
  //         navigate("/signup");
  //       }
  //       return;
  //     } catch (error) {
  //       toast("Wrong Credentials! Please enter valid credentials");
  //       console.error(error);
  //       return;
  //     }
  //   }
  // };


  const adminLogin = async (data) => {
    const { number, password } = data;
    if (number && password) {

      const payload = {
        mobileNo: number,
        password
      }
      try {
        const resp = await loginEmployee(payload);
        console.log(resp)
          toast(`${resp}`);
          dispatch(setAttendance(resp));
          navigate("/signup");
        } catch (error) {
        toast("Wrong Credentials! Please enter valid credentials");
        return;
      }
    }
  };

  const adminLoginInitialValues = {
    number: "",
    password: "",
  };

  const validationSchemaLoginAdmin = Yup.object().shape({
    number: Yup.string()
      .required("Contact number is required")
      .matches(/^[0-9]{10}$/, "Contact number should have exactly 10 digits"),
    password: Yup.string().required("Password is required").trim(),
  });

  const imgUrl =
    "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <div
      className="bg-slate-400 h-screen w-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
      }}
    >
      <div className="bg-gray-100 bg-opacity-55 backdrop-blur-sm p-8 rounded-md w-96 shadow-md">
        <div className="mb-10 flex justify-center">
        <img
            src={uploadLogo}
            className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] my-5"
            alt="Salon Logo"
          />
        </div>
        <Formik
          initialValues={adminLoginInitialValues}
          validationSchema={validationSchemaLoginAdmin}
          onSubmit={adminLogin}
          enableReinitialize
        >
          {(formikProps) => (
            <Form name="adminLogin">
              <Stack spacing={2}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Employee Attendance
                </Typography>
                <Field
                  fullWidth
                  variant={MUI_VARIANT}
                  size={MUI_SIZE}
                  component={TextField}
                  placeholder="Contact Number"
                  label="Contact Number"
                  type="number"
                  name={"number"}
                  id={"number"}
                  sx={{
                    height: MUI_TEXT_FIELD_HIGHT,
                  }}
                  InputProps={FieldIcon(<PhoneAndroid />)}
                />
                <Field
                  fullWidth
                  variant={MUI_VARIANT}
                  size={MUI_SIZE}
                  component={TextField}
                  placeholder="Password"
                  label="Password"
                  id="password"
                  name="password"
                  sx={{
                    height: MUI_TEXT_FIELD_HIGHT,
                  }}
                  InputProps={FieldIcon(<KeyIcon />)}
                />
              </Stack>
              <LoadingButton
                loading={formikProps.isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
              >
                Done
              </LoadingButton>
              <LoadingButton
                loading={formikProps.isSubmitting}
                onClick={handleLogout}
                fullWidth
                variant="outlined"
                sx={{ mt: 3 }}
              >
                Back to Home
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
