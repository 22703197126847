import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Chip, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import TimeSheetTable from './TimeSheetTable';
import { STAFF_ENDPOINT } from '../../../../../api/salon-api/salon-service';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { setTimeSheet } from '../../../../../redux/feature/employeeTimesheetSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BaseCard } from '../../../../../components/Mui';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

export default function TimeSheets() {
  const theme = useTheme();
  const [personId, setPersonId] = useState('');
  const [tableData, setTableData] = useState([]);
  const employeeData = useSelector((state) => state.Employee.employeeData.content);
  const timeSheetData = useSelector((state) => state.TimeSheet);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const getTimeSheetData = async (personId) => {
    const isFound = timeSheetData ? timeSheetData.find((item) => item.id === personId) : false;
    if (!isFound) {
      try {
        const response = await axiosPrivate({
          method: 'GET',
          url: `${STAFF_ENDPOINT}/staff-timeSheet/${personId}`,
        });
        dispatch(setTimeSheet({ id: response?.data[0]?.id || null, timeSheetArray: response.data.length > 0 ? response.data : [] }));
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching time sheet data:', error);
      }
    }
  };

  return (
    <BaseCard sx={{ p: 0 }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Grid container spacing={2} alignItems="center" width="45%">
            <Grid item>
              <Typography style={{ margin: '4px' }} variant="h6" color="textSecondary" align="center">
                Time Sheets
              </Typography>
            </Grid>
            <Grid item xs>
              <FormControl variant="filled" sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="select-standard-label">Select Employee</InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard-label"
                  onChange={(event) => {
                    setPersonId(event.target.value);
                    getTimeSheetData(event.target.value);
                  }}
                  value={personId}
                  label="Select Employee"
                >
                  {employeeData.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item.name, personId, theme)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="right" width="55%">
            <Grid item>
              <Chip label="Export" color="primary" icon={<FileDownloadIcon />} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {!personId ? (
        <Typography style={{ margin: '40px 16px' }} color="textSecondary" align="center">
          No Time Sheets available
        </Typography>
      ) : (
        <TimeSheetTable data={tableData} />
      )}
    </BaseCard>
  );
}
