import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { UPLOAD_LOGO_ENDPOINT } from '../../../api/salon-api/salon-service';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { setLogo } from '../../../redux/feature/uploadLogoSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function UploadLogoModal({ open, handleClose }) {

  const [logoFile, setLogoFile] = React.useState(null);
  const [logoUrl, setLogoUrl] = React.useState("");
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const Salon = useSelector((state) => state.SalonDetails?.Salon_data)

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      console.log("File uploaded:", file);

      const desiredFileSize = (file.size / (1024 * 1024)).toFixed(2);
      if(desiredFileSize > 2){
        toast.warn("File size is too large ! Image should be less that 2 mb");
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axiosPrivate({
          method: 'POST',
          url: `${UPLOAD_LOGO_ENDPOINT}/upload?salonId=${Salon?.id}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const imageUrl = response.data.presignedUrl.split('?')[0];
        setLogoUrl(imageUrl);
        dispatch(setLogo({ key: response.data.key, presignedUrl: imageUrl }));
        toast("Logo Uploaded");
      } catch (error) {
        console.error('Error Uploading Logo:', error);
        toast("Error Uploading Logo");
      }
    }
  };

  const handleUploadClick = () => {
    document.getElementById('logo-upload').click();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Please Upload the Logo for your Salon</h2>
          {logoUrl && <img src={`${logoUrl}`} width={'200px'} height={'200px'} alt="Logo Preview" />}
          <input
            type="file"
            id="logo-upload"
            style={{ display: 'none' }}
            onChange={handleLogoChange}
          />
          <Button
            onClick={handleUploadClick}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 3 }}
          >
            <DriveFolderUploadIcon sx={{mr : 1}}/> Upload Logo
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            variant="outlined"
            color="primary"
          >
           Done
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
