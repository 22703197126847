import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IVendorEntity } from "../../SystemManagement/ConfigureServices/types/configService";
import {
  useDeleteVendor,
  usePaginatedVendorController,
  useUpdateVendor,
} from "../../../../../hooks/api/salonService/vendorController/useAllVendor";
import EnhancedTableHead from "../../../../../components/Mui/table/Table";
import { headCellsInventory } from "./inventoryConstant";
import {
  useAllSalonInventoryController,
  useDeleteInventory,
  useUpdateInventory,
} from "../../../../../hooks/api/inventory-service/useSalonInventoryController";
import { truncateText } from "../../../../../helper/Common/text";
import { InventoryContent } from "../../../../../hooks/api/inventory-service/inventoryService";
import InventoryForm from "./formUi/InventoryForm";
import RefillSalonInventory from "./formModal/RefillSalonInventory";
import { toast, ToastContainer } from "react-toastify";

type TMode = "EDIT" | "ADD";
export interface IOpenInventoryForm {
  mode: TMode;
  open: boolean;
  data: InventoryContent | null;
}
const initialState: IOpenInventoryForm = {
  mode: "ADD",
  open: false,
  data: null,
};

interface IPropsUpdate {
  newQuantity: number;
  data: InventoryContent | null;
}

interface SalonInventoryData {
  salonId: number;
  vendorId: number;
  quantity: number;
  productId: number;
  threshold: number;
  description: string;
  status: string;
  measurementUnit: string;
}

const InventoryTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected] = useState([]);
  const [openInventoryForm, setOpenInventoryForm] =
    useState<IOpenInventoryForm>(initialState);

  const {
    isLoading,
    currentPage: page,
    pageSize: rowsPerPage,
    setPage,
    setSize: setRowsPerPage,
    allInventory,
  } = useAllSalonInventoryController(1, 5);

  const { updateVendor } = useUpdateVendor();
  const { deleteVendor } = useDeleteVendor();

  const { updateInventory } = useUpdateInventory();
  const { deleteInventory } = useDeleteInventory();

  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState<InventoryContent | null>(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleTransfer = (data: InventoryContent | null) => {
    handleOpen();
    if (data) setRowData(data);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    // if (newPage < 1) return;
    setPage(newPage + 1);
  };
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseInventoryForm = () => {
    setOpenInventoryForm(initialState);
  };

  const handleOpenInventoryForm = (
    mode: TMode,
    data: null | InventoryContent
  ) => {
    setOpenInventoryForm({ mode, open: true, data });
  };

  const handleDeleteInventory = async (id: number) => {
    await deleteInventory(id);
  };

  const handleUpdateInventory = async ({ data, newQuantity }: IPropsUpdate) => {
    try {
      // Ensure data is not null before proceeding
      if (!data) {
        throw new Error('Data is null');
      }
      // Handle undefined 'threshold' by providing a default value (e.g., 0)
      const resp = await updateInventory({
        ...data,
        quantity: data.quantity ? data.quantity + newQuantity : newQuantity,
        threshold: data.threshold ?? 0, // Ensure 'threshold' is always a number, use 0 if undefined
      });
  
      if (resp) {
        toast.success('Inventory updated successfully');
      }
    } catch (error) {
      toast.error(`Error updating inventory`);
      console.log(error)
    } finally {
      handleOpen();
    }
  };
  

  const handleChangeSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: InventoryContent
  ) => {
    const checked = event.target.checked;
    const requestBody = {
      ...row,
      productId: row.productEntity.id,
      vendorId: row?.vendorEntity?.id,
      status: checked ? "ACTIVE" : "INACTIVE",
    } as InventoryContent;
    try {
      await updateInventory(requestBody);
    } catch (error) {
      console.error("error", error);
    }
  };

  const tableInclose = (
    <Table>
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={allInventory?.content.length}
        headCells={headCellsInventory}
      />
      <TableBody>
        {allInventory?.content?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index: number) => {
          // const isItemSelected = isSelected(row.id);
          {
            console.log(row);
          }
          const labelId = `service-table-${index}`;

          return (
            <TableRow
              sx={{
                bgcolor: row.status === "DELETED" ? "gray" : "white",
                cursor: "pointer",
                mx: 4,
              }}
              role="checkbox"
              tabIndex={-1}
            >
              <TableCell component="th" id={labelId} scope="row">
                {row?.measurementUnit}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.quantity}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.threshold}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.productEntity?.name}
              </TableCell>
              {/* <TableCell component="th" id={labelId} scope="row">
                <Tooltip title={row?.description}>
                  <Stack>{truncateText(row?.description, 15, "...")}</Stack>
                </Tooltip>
              </TableCell> */}
              <TableCell component="th" id={labelId} scope="row">
                <Switch
                  sx={{ mx: 1 }}
                  checked={row.status === "ACTIVE" ? true : false}
                  onChange={(e) => handleChangeSwitch(e, row)}
                />
              </TableCell>

              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
              >
                <Stack direction="row">
                  <ModeEditIcon
                    sx={{ mx: 1 }}
                    onClick={() => handleOpenInventoryForm("EDIT", row)}
                  />
                  {row.quantity <= row.threshold && (
                    <Chip
                      label="Refill"
                      color="success"
                      onClick={() => handleTransfer(row)}
                    />
                  )} 
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Stack width={"100%"}>
      <TableContainer>
        <Toolbar>
          <Grid container spacing={2} alignItems="center" width={"45%"}>
            <Grid item>
              <SearchIcon color="inherit" style={{ display: "block" }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by Service Name"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "default" },
                }}
                variant="standard"
                size="small"
                // onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <Chip
                label="Search"
                onClick={() => {
                  console.log("search");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" width={"55%"}>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleOpenInventoryForm("ADD", null)}
              >
                Add Inventory
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <RefillSalonInventory
          open={open}
          handleOpen={handleOpen}
          data={rowData}
          handleUpdateInventory={({ data, newQuantity }) =>
            handleUpdateInventory({ data, newQuantity })
          }
        />

        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {!allInventory?.content?.length ? (
              <Typography variant="h6" align="center">
                No data available
              </Typography>
            ) : (
              tableInclose
            )}
          </>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allInventory?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <InventoryForm
        openInventoryForm={openInventoryForm}
        handleCloseInventoryForm={handleCloseInventoryForm}
      />
    </Stack>
  );
};

export default InventoryTable;
