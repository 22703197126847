import { createSlice } from "@reduxjs/toolkit";

export const attendanceSlice = createSlice({
    name: "Attendance",
    initialState: {},
    reducers: {
        setAttendance: (state, action) => {
            return action.payload
        },
        removeAttendance: (state, action) => {
            return null;
        }
    }
})

export const { setAttendance, removeAttendance } = attendanceSlice.actions
export default attendanceSlice.reducerf