import { useNavigate } from "react-router-dom";
import { loginAdmin, loginSuperAdmin } from "../../../api/salon-api/auth-service";
import { IAdminLogin } from "../Admin/admin.d.ts/adminLogin";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../../redux/feature/authSlice";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../helper/Common/constant";
import { FieldIcon } from "../../../components/Mui/Formik/FieldIcon";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SalonLogo } from "../../../assets/img";
import { requestForToken } from "../../../firebase/firebase";

interface RootState {
  common :{
    salonId : string
  }
}

export default function SuperAdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salonId = useSelector((state: RootState) => state.common.salonId)
  // Todo: Need to fix routing


  const superadminLogin = async (data: IAdminLogin) => {
    const { email, password } = data;
    try {
      const fcmToken = await requestForToken() as string;
      const token = await loginSuperAdmin(email, password, salonId, fcmToken);
      // const { token } = data;
      toast("Successfully Logged in!");
      dispatch(setLogin({ token, email }));
      navigate("/salonOnboardingForm");
      
    } catch (error) {
      toast("Wrong Credentials! Please enter valid credentials");
      console.error(error);
    }
  };

  const adminLoginInitialValues = {
    email: "",
    password: "",
  };

  const validationSchemaLoginAdmin = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });

  return (
    <>
      <div
        className="bg-slate-400 h-screen w-screen flex items-center justify-center"
        style={{
          backgroundImage: `url(${"https://images.unsplash.com/photo-1600948836101-f9ffda59d250?q=80&w=1672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-gray-100 bg-opacity-55 backdrop-blur-sm p-8 rounded-md w-96 shadow-md">
          <div className="mb-10 flex justify-center">
            <img
              src={SalonLogo}
              className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] my-5"
            />
          </div>
          <Formik
            initialValues={adminLoginInitialValues}
            validationSchema={validationSchemaLoginAdmin}
            onSubmit={superadminLogin}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form name="Salon Onboarding">
                  <Stack spacing={2}>
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter your email"
                      label="Email super admin"
                      type="email"
                      name={"email"}
                      id={"email"}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      // helperText="Please enter your name"
                      InputProps={FieldIcon(<EmailIcon />)}
                    />
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter your password"
                      label="Password"
                      type="password"
                      name={"password"}
                      id={"password"}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      // helperText="Please enter your name"
                      InputProps={FieldIcon(<KeyIcon />)}
                    />
                  </Stack>
                  <LoadingButton
                    loading={formikProps.isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3 }}
                  >
                    Login
                  </LoadingButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
