import React, { useState } from "react";
import { IConfigServiceData } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../redux/feature/slice";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  ALL_SERVICE_BY_SERVICE_CATEGORY_ID,
  SERVICE_ALL_ENDPOINT,
} from "../../../api/salon-api/salon-service";
import { setServiceData } from "../../../redux/feature/salonServiceSlice";

export interface IServiceDataLProps {
  serviceListData?: IConfigServiceData | null;
  type?: string | null;
}

function SelectService({ serviceListData, type }: IServiceDataLProps) {
  const serviceCategoryData = useSelector(
    (state: RState) => state?.services?.serviceCategories?.content
  );
  const [activeCategory, setActiveCategory] = useState<number | null>(0); // null for better type safety
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const SalonDetails = useSelector(
    (state: RState) => state?.SalonDetails?.Salon_data
  );

  const fetchService = async () => {
    try {
      const params = {
        page: 1,
        size: 500,
        salonId: SalonDetails?.id,
      };

      const response = await axiosPrivate.post<IConfigServiceData>(
        SERVICE_ALL_ENDPOINT,
        params
      );

      if (response.status === 200) {
        dispatch(setServiceData(response.data));
        setActiveCategory(0); // Default to "All"
      } else {
        toast.warn("Failed to fetch services");
      }
    } catch (error: any) {
      console.error("Error fetching services:", error);
    }
  };

  const handleFilterService = async (id: number | undefined) => {
    try {
      const result = await axiosPrivate({
        method: "GET",
        url: `${ALL_SERVICE_BY_SERVICE_CATEGORY_ID}/${id}/${SalonDetails?.id}`,
      });

      dispatch(
        setServiceData({
          totalElements: result.data.length,
          totalPages: 1,
          page: 1,
          content: result.data,
        })
      );

      setActiveCategory(id ?? 0); // Correctly set the active category
    } catch (error) {
      console.log("error ::", error);
      toast.warn("Service not found!");
    }
  };

  if (!serviceCategoryData) return null;

  return (
    <div className="border-2 border-gray-400 px-3 py-4 relative h-full w-full rounded-lg">
      <p className="absolute px-3 font-semibold italic -top-7 left-9 bg-slate-100 py-1 text-xl border-2 border-gray-400 rounded-lg">
        Select a Service
      </p>
      <div className="flex w-full flex-wrap gap-3">
        {serviceCategoryData
          .filter(
            (item) =>
              item.status === "ACTIVE" &&
              (SalonDetails?.salonTypeStatus.toUpperCase() === "UNISEX"
                ? item.gender.toLocaleUpperCase() === type?.toUpperCase()
                : item.gender.toUpperCase() === SalonDetails?.salonTypeStatus)
          )
          .map((item) => (
            <div
              key={item.id}
              className="flex items-center shadow-sm px-3 py-2 border-2 border-gray-400 rounded-lg bg-gray-900 text-white cursor-pointer"
              onClick={() => handleFilterService(item?.id)}
            >
              {activeCategory === item.id && (
                <p className="h-2 w-2 rounded-full bg-green-400 mr-2"></p>
              )}
              <div>{item.categoryName}</div>
            </div>
          ))}
        <div
          className="flex gap-2 items-center shadow-sm px-3 py-2 border-2 border-gray-400 rounded-lg bg-gray-900 text-white cursor-pointer"
          onClick={fetchService}
        >
          {activeCategory === 0 && (
            <p className="h-2 w-2 rounded-full bg-green-400"></p>
          )}
          <div>All</div>
        </div>
      </div>
    </div>
  );
}

export default SelectService;
