import React, { useEffect, useState, useRef } from "react";

const NavigationTimer = ({ onExpire, duration = 180000 }) => { // Default duration is 3 minutes
  const [currentTime, setCurrentTime] = useState(duration);
  const intervalRef = useRef(null);

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    const startTimer = () => {
      intervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(intervalRef.current);
            onExpire(); // Call the provided function when time expires
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    };

    startTimer();

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [onExpire]);

  return <p>Order Cancellation Timer: {formatTime(currentTime)}</p>;
};

export default NavigationTimer;
