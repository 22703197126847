export const STAFF_ENDPOINT = "v1/salon-staff";

export const STAFF_ALL_ENDPOINT = "v1/salon-staff/getAll";

export const STAFF_ALL_ATTENDANCE_ENDPOINT = "v1/staff-attendance/all";

export const SERVICE_CATEGORY_ALL_ENDPOINT = "v1/service-category/all";

// Salon Service Controller
export const SALON_SERVICE_ENDPOINT = "v1/salon-service";
export const SERVICE_ALL_ENDPOINT = "/v1/salon-service/all";
export const SALON_SERVICE_UPLOAD_IMG ="v1/upload-logo/upload-salon-service-image";
export const ALL_SERVICE_BY_SERVICE_CATEGORY_ID = "v1/salon-service/allServiceByServiceCategoryId"
export const GET_SALON_STAFF_ENDPOINT = "v1/salon-staff"


// Service category
export const SERVICE_CATEGORY_ENDPOINT = "v1/service-category";

// Upload Logo
export const UPLOAD_LOGO_ENDPOINT = "v1/upload-logo";

// Upload staff image
export const UPLOAD_STAFF_ENDPOINT = "/v1/upload-staff-photo/upload";

// Salon Onboarding API
export const SALON_ONBOARDING_ENDPOINT = "onboarding/salon";

// Face Recognition API
export const EMPLOYEE_FACE_RECOGNITION_ENDPOINT = "api/recognition/compare-faces-real";

// Attendance API Endpoint
export const EMPOLOYEE_ATTENDANCE_CHECKIN_ENDPOINT = "v1/staff-attendance/checkIn"
export const EMPOLOYEE_ATTENDANCE_CHECKOUT_ENDPOINT = "v1/staff-attendance/checkOut"

// Reports Endpoint API
export const SERVICE_REPORTS = "v1/reports/servicesReport"
export const PAYMENT_REPORTS = "v1/reports/paymentReport"
export const EMPLOYEE_REPORTS = "v1/reports/employeeReport"
export const DAY_END_SUMMARY_REPORTS = "v1/reports/dayEndSummaryReport"
export const CUSTOMER_REPORTS = "v1/reports/customerReport"


//Vendor
export const VENDOR_ENDPOINT = "v1/vendors";


//Auth Service
export const CREATE_ADMIN_ENDPOINT = "auth/create"