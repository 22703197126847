import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack, TextField } from '@mui/material';
import { InventoryContent } from '../../../../../../hooks/api/inventory-service/inventoryService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface IPROPS {
    open: boolean
    handleOpen: () => void
    data: InventoryContent | null
    handleUpdateInventory: (
      {
        newQuantity,
        data
      } : IPropsUpdate
    ) => void
}
interface IPropsUpdate {
  data: InventoryContent | null;
  newQuantity: number;
}


export default function RefillSalonInventory({open, handleOpen, handleUpdateInventory, data}: IPROPS) {
  const [newQuantity, setNewQuantity] = React.useState(0);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Refill Quantity
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            Enter the quantity to be added for the inventory
          </Typography>
          <TextField id="outlined-basic" label="Refill Quantity" variant="outlined" fullWidth value={newQuantity} onChange={(e) => setNewQuantity(Number(e.target.value))} />
          <Stack gap={2} direction={'column'} marginTop={2}>
          <Button color='primary' variant='contained' onClick={async() => {
            handleUpdateInventory({ data, newQuantity })
            setNewQuantity(0);
          }}>Add</Button>
            <Button color='error' variant='outlined' onClick={handleOpen}>Cancel</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}