export function getCurrentTime() {
  const currentDate = new Date();
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let todayDate = currentDate.getDate();
  let todayMonth = currentDate.getMonth();
  let todayYear = currentDate.getFullYear();
  return {
    currentTime: 'Time: ' + hours + ':' + minutes,
    currentDate: 'Date: ' + todayDate + '/' + todayMonth + '/' + todayYear,
  };
}

export function getDaysInMonth(month, year) {
  if (month < 1 || month > 12) {
    return -1;
  }
  if (!year) {
    year = new Date().getFullYear();
  }
  const lastDay = new Date(year, month, 0);
  return lastDay.getDate();
}

export function getDateFormat(currentDate) {
  console.log("Input date:", currentDate);
  try {
    currentDate = new Date(currentDate);
    console.log("Parsed date:", currentDate);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    console.log("Formatted date:", formattedDate);
    return formattedDate;
  } catch (error) {
    console.error("Error parsing date:", error);
    return ""; // or some default value
  }
}


export function getNumberOfDays(startDate, endDate) {
  const startMillis = startDate.getTime();
  const endMillis = endDate.getTime();
  const differenceMillis = endMillis - startMillis;
  const daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
  const numberOfDays = daysDifference;

  return numberOfDays + 1;
}

export function getTimeFormat(now) {
  // Ensure now is a Date object
  if (!(now instanceof Date)) {
    now = new Date(now); // Assuming now is in a format recognized by the Date constructor
  }

  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}



/**
 * Function to format a date in YYYY-MM-DD format.
 *
 * @param {Date} currentDate - The input date to be formatted.
 * @return {string} The formatted date in YYYY-MM-DD format.
 */
export function getDateFormatYYYYMMDD(currentDate) {
  currentDate = new Date(currentDate);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


export function handleTimeConvertion(timeInMinutes) {
  if(typeof timeInMinutes !== 'number') return "";
  let valueTime = timeInMinutes
  if(timeInMinutes < 0){
    valueTime = timeInMinutes * -1;
  }
  let hours = Math.floor(valueTime / 60); // Calculate the full hours
  let minutes = valueTime % 60; // Calculate the remaining minutes after converting to hours

  return `${hours} hr ${minutes} min`;
}