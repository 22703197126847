import React, { useRef, useState, useCallback, useEffect } from "react";
import swal from "sweetalert";
import Webcam from "react-webcam";
import { LoadingButton } from "@mui/lab";
import { SalonLogo } from "../../../assets/img";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  EMPLOYEE_FACE_RECOGNITION_ENDPOINT,
  EMPOLOYEE_ATTENDANCE_CHECKIN_ENDPOINT,
  EMPOLOYEE_ATTENDANCE_CHECKOUT_ENDPOINT,
  GET_SALON_STAFF_ENDPOINT,
} from "../../../api/salon-api/salon-service";
import { useSelector } from "react-redux";
import { useDefaultDates } from "@mui/x-date-pickers/internals";
import { toast } from "react-toastify";

export default function EmployeeLogin() {
  const navigate = useNavigate();
  const uploadLogo = useSelector((state) => state.UploadLogo?.logo);
  const webcamRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);

  const [isAttendanceMarked, setIsAttendanceMarked] = useState(false);

  const handleLogout = () => {
    navigate("/customer/signup");
  };

  const handleAction = () => {
    navigate("/scanner");
  }

  const startCamera = () => {
    setCameraActive(true);
  };

  const stopCamera = () => {
    setCameraActive(false);
  };

  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      stopCamera();
    }
  }, [webcamRef]);

  const confirmImage = async () => {
    try {
      console.log("Captured Image Data:", capturedImage);
      if (capturedImage) {
        // Convert the image to Base64
        const base64Image = capturedImage.split(",")[1]; // Remove the data:image/jpeg;base64, part
        const staffId = window.localStorage.getItem("staffId");
        const response = await axiosPrivate({
          method: "POST",
          url: EMPLOYEE_FACE_RECOGNITION_ENDPOINT,
          data: { userPhotoUrl: base64Image, staffId: staffId },
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Server Response:", response.data);

        swal({
          icon: "success",
          title: "Attendance Marked",
          text: "Thank You for Punching In. We Welcome you to the Salon",
          buttons: "Done",
        }).then((response) => {
          if (response) {
            navigate("/employeeLogin");
          }
          setCapturedImage(null);
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "There was an error marking your attendance. Please try again.",
        buttons: "OK",
      });
    }
  };

  // const imgUrl = uploadLogo;
  const imgUrl =
    "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  async function handleCheckin() {
    try {
      const staffId = window.localStorage.getItem("staffId");
      const formattedDate = new Date(Date.now()).toISOString();
      const response = await axiosPrivate({
        method: "POST",
        url: EMPOLOYEE_ATTENDANCE_CHECKIN_ENDPOINT,
        data: {
          staffId: staffId,
          checkInTime: `${formattedDate}`,
          checkOutTime: `${formattedDate}`,
          status: "ACTIVE",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Server Response:", response.data);
      swal({
        icon: "success",
        title: "CheckIn Successfull",
        text: "Thank You for Checking In. We Welcome you to the Salon",
        buttons: "Done",
      }).then((response) => {
        if (response) {
          navigate("/employeeLogin");
        }
      });
    } catch (error) {
      console.error("Error:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "Error Checking In! Please try again.",
        buttons: "OK",
      });
    }
  }

  async function handleCheckout() {
    try {
      const staffId = window.localStorage.getItem("staffId");
      const formattedDate = new Date(Date.now()).toISOString();
      const response = await axiosPrivate({
        method: "PUT",
        url: `${EMPOLOYEE_ATTENDANCE_CHECKOUT_ENDPOINT}/${staffId}`,
        data: {
          staffId: staffId,
          checkInTime: `${formattedDate}`,
          checkOutTime: `${formattedDate}`,
          status: "ACTIVE",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Server Response:", response.data);

      swal({
        icon: "success",
        title: "Checkout Completed",
        text: "Thank You for Checking Out.",
        buttons: "Done",
      }).then((response) => {
        if (response) {
          navigate("/employeeLogin");
        }
      });
    } catch (error) {
      console.error("Error:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "Error Checking Out! Please try again.",
        buttons: "OK",
      });
    }
  }


  return (
    <div
      className="bg-slate-400 h-screen w-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
      }}
    >
      <div className="bg-gray-100 bg-opacity-55 backdrop-blur-sm p-8 rounded-md w-96 shadow-md">
        <div className="mb-10 flex justify-center">
          <img
            src={uploadLogo}
            className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] my-5"
            alt="Salon Logo"
          />
        </div>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Please marked your Attendance
        </Typography>
        {/* Face Recognition is currently disbanned */}
        {/* {!cameraActive && !capturedImage && (
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            onClick={startCamera}
          >
            Mark Attendance
          </LoadingButton>
        )}
        {cameraActive && (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{ width: "100%", marginTop: "1rem" }}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              onClick={captureImage}
            >
              Scan Face
            </LoadingButton>
          </>
        )}
        {capturedImage && (
          <>
            <img
              src={capturedImage}
              alt="Captured"
              style={{ width: "100%", marginTop: "1rem" }}
            />
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button variant="outlined" onClick={startCamera}>
                Re-capture
              </Button>
              <Button variant="contained" onClick={confirmImage}>
                Confirm
              </Button>
            </Box>
          </>
        )} */}
        {/* {
          isAttendanceMarked ? (
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            onClick={handleCheckout}
          >
            Check Out
          </LoadingButton>
          ) : (
            <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            onClick={handleCheckin}
          >
            Check In
          </LoadingButton>
          )
        } */}
        <LoadingButton
          fullWidth
          variant="contained"
          color="info"
          sx={{ mt: 3 }}
          onClick={handleAction}
        >
          Check In
        </LoadingButton>
        <LoadingButton
          fullWidth
          variant="contained"
          color="error"
          sx={{ mt: 3 }}
          onClick={handleAction}
        >
          Check Out
        </LoadingButton>
        <LoadingButton
          onClick={handleLogout}
          fullWidth
          variant="outlined"
          sx={{ mt: 3 }}
        >
          Exit
        </LoadingButton>
      </div>
    </div>
  );
}
