import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../useAxiosPrivate";
import { useEffect, useState } from "react";
import { RState } from "../../../../redux/feature/slice";
import { setAddVendor, setAllVendor, setDeleteVendor, setUpdateVendor } from "../../../../redux/feature/salonServiceSlice";
import { IVendorControllerData, IVendorEntity } from "../../../../pages/SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { VENDOR_ENDPOINT } from "../../../../api/salon-api/salon-service";

export const usePaginatedVendorController = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)
  const vendorData = useSelector((state: RState) => state.services.vendorData);

  const fetchVendorController = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = !all
        ? {
            page: currentPage,
            size: 500,
          }
        : {
          size: 500,
        };

      const response = await axiosPrivate.post<IVendorControllerData>(
        `${VENDOR_ENDPOINT}/all`,
        {...params, salonId : SalonDetails?.id ? SalonDetails?.id : 2}
      );

      if (response.status === 200) {
        dispatch(setAllVendor(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      currentPage !== vendorData?.page ||
      pageSize !== vendorData?.totalPages
    ) {
      fetchVendorController();
    } else {
      setIsLoading(false);
    }
  }, [
    // currentPage,
     pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
    vendorData,
  };
};


export const useCreateVendor = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)
  const createVendor = async (data: IVendorEntity) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.post<IVendorEntity>(
        `${VENDOR_ENDPOINT}`,
        { ...data, salonId: SalonDetails?.id ? SalonDetails?.id : 2 }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setAddVendor(response.data));
      } else {
        setError(new Error("Failed to create vendor"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error creating:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    createVendor,
  };
};


export const useUpdateVendor = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const updateVendor = async (data: IVendorEntity) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.put<IVendorEntity>(
        `${VENDOR_ENDPOINT}/${data.id}`,
        { ...data }
      );

      if (response.status === 200) {
        dispatch(setUpdateVendor(response.data));
      } else {
        setError(new Error("Failed to update vendor"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateVendor,
  };
};


export const useDeleteVendor = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const deleteVendor = async (id: number) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.delete<IVendorEntity>(
        `${VENDOR_ENDPOINT}/${id}`
      );

      if (response.status === 200) {
        dispatch(setDeleteVendor(id));
      } else {
        setError(new Error("Failed to create product"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error creating:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    deleteVendor,
  };
};
