import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DELETE_ORDER_BOOK,
  ORDER_BOOK_ENDPOINT,
} from "../../../api/salon-api/log-book-service";
import useAxiosPrivate, {
  useAxiosPrivateLogBook,
} from "../../../hooks/useAxiosPrivate";
import { LoadingButton } from "@mui/lab";
import { resetOrderBook } from "../../../redux/feature/orderBookSlice";

export default function PaymentWindow({ Tip, TotalPrice }) {
  const navigate = useNavigate();
  const axiosPrivateLogBook = useAxiosPrivateLogBook();
  const PaymentQR = useSelector((state) => state?.paymentQR?.paymentQRCode);
  const OrderBook = useSelector((state) => state?.OrderBook);
  const SalonId = useSelector((state) => state?.SalonDetails?.Salon_data?.id);
  const CustomerId = useSelector((state) => state.CustomerCred.token.id);

  const [open, setOpen] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const dispatch = useDispatch();

  const handlePaymentClick = async () => {
    await UpdateOrderBook(OrderBook.id);
    window.localStorage.removeItem("orderBookId");
    navigate("/logout");
  };

  const handleOpen = () => {
    setOpenQR(false);
    setOpen(!open);
  };

  const handleOpenQR = () => {
    setPaymentMethod("UPI");
    setOpen(!open);
    setOpenQR(!openQR);
  };

  const handleCancelPayment = () => {
    setOpen(false);
    setOpenQR(false);
  };

  const UpdateOrderBook = async (id = OrderBook?.id) => {
    setLoading(true);
    try {
      await axiosPrivateLogBook({
        method: "PUT",
        url: `${ORDER_BOOK_ENDPOINT}/${id}`,
        data: {
          customerId: CustomerId,
          salonId: SalonId,
          totalCost: Tip > 0 ? Tip + TotalPrice : TotalPrice,
          paymentType: paymentMethod,
        },
      });
      setLoading(false);
      handleOpen();
    } catch (error) {
      console.error("Error Updating Order Book data:", error);
      setLoading(false);
    }
  };

  const removeOrderBookDetails = async (id) => {
    if (id) {
      try {
        await axiosPrivateLogBook({
          method: "DELETE",
          url: `${DELETE_ORDER_BOOK}/${id}`,
        });
        window.localStorage.removeItem("orderBookId");
        dispatch(resetOrderBook());
        navigate("/");
      } catch (error) {
        console.error("Error resetting Order Book data:", error);
      }
    }
  };

  return (
    <>
    <Stack direction={"row"} gap={3}>
    <LoadingButton
        loading={loading}
        variant="contained"
        color="error"
        onClick={() => removeOrderBookDetails(OrderBook.id)}
      >
        Cancel Order
      </LoadingButton>

      <LoadingButton
        loading={loading}
        variant="contained"
        color="success"
        onClick={() => UpdateOrderBook(OrderBook.id)}
      >
        Pay
      </LoadingButton>
    </Stack>

      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleOpen}>
        <DialogTitle>Select Your Preferred Payment Option</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack gap={2} display={"flex"} direction={"column"}>
              <Stack
                sx={{
                  height: "100px",
                  backgroundColor: "#E2E8F0",
                  cursor: "pointer",
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                direction="row"
                gap={2}
                onClick={handleOpenQR}
              >
                <img src="/upi.png" height="100px" width="100px" alt="UPI" />
                <Typography variant="h3">UPI</Typography>
              </Stack>
              <Stack
                sx={{
                  height: "100px",
                  backgroundColor: "#E2E8F0",
                  cursor: "pointer",
                }}
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
                onClick={handlePaymentClick}
              >
                <img src="/cash.png" height="100px" width="100px" alt="Cash" />
                <Typography variant="h3">Cash</Typography>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openQR}
        onClose={handleOpenQR}
      >
        <DialogTitle>Please Scan this QR to make Payment</DialogTitle>
        <DialogContent>
          <Stack gap={2} display={"flex"} direction={"column"}>
            <Stack
              sx={{ cursor: "default" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              direction="row"
              gap={2}
            >
              <img src={PaymentQR} alt="Payment QR Code" />
            </Stack>
          </Stack>
          <Stack gap={2} display={"flex"}>
            <DialogActions>
              <Button
                color="error"
                variant="outlined"
                onClick={handleCancelPayment}
              >
                Cancel
              </Button>
              <Button
                color="success"
                onClick={handlePaymentClick}
                variant="contained"
              >
                Payment Done
              </Button>
            </DialogActions>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
