import { createSlice } from "@reduxjs/toolkit";

export const OrderBookSlice = createSlice({
    name: "Order-Book",
    initialState: null,
    reducers: {
        setOrderBook: (state, action) => {
            return action.payload;
        },
        resetOrderBook: (state, action) => {
            return null
        }
    },
});

export const { setOrderBook, resetOrderBook } = OrderBookSlice.actions;

export default OrderBookSlice.reducer;
