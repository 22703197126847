import React from "react";
import { Stack } from "@mui/material";
import { Field } from "formik";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import { TextField } from "formik-mui";
import { PRODUCT_FIELD_NAMES } from "../formModal/initialValueProducts";
import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";

// icons
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LabelIcon from "@mui/icons-material/Label";
import ScaleIcon from "@mui/icons-material/Scale";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { IOpenProductForm } from "../ProductTable";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";
import MultiSelectFormik from "../../../../../../components/Mui/Formik/MultiSelectFormik";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { usePaginatedVendorController } from "../../../../../../hooks/api/salonService/vendorController/useAllVendor";
import { INVENTORY_FIELD_NAMES } from "../../SalonInventory/formModal/inventoryInitialValue";
interface IProductFormUI {
  openProductForm: IOpenProductForm;
}
const ProductFormUI: React.FC<IProductFormUI> = () => {
  const { vendorData } = usePaginatedVendorController(0, 0, true);
  const vendorDataList = vendorData?.content
    .filter((item) => item.status === "ACTIVE")
    .map((vendor) => {
      return { value: vendor.id, label: vendor.name };
    });
  return (
    <div>
      <Stack py={1} flexDirection={"column"} spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter product name"
            label="Product Name"
            type="text"
            name={PRODUCT_FIELD_NAMES.name}
            id={PRODUCT_FIELD_NAMES.name}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<ShoppingCartIcon />)}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter product brand name"
            label="Brand Name"
            type="text"
            name={PRODUCT_FIELD_NAMES.brandName}
            id={PRODUCT_FIELD_NAMES.brandName}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<LabelIcon />)}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter product usage quantity"
            label="Product Usage Quantity"
            type="number"
            name={PRODUCT_FIELD_NAMES.avgUsageQuantity}
            id={PRODUCT_FIELD_NAMES.avgUsageQuantity}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<GasMeterIcon />)}
          />
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={SelectField}
            data={[
              {
                label: "Milli-liter (ml)",
                value: "MILLILITER",
              },
              {
                label: "Piece (1n)",
                value: "PIECE",
              },
              {
                label: "Gram (gm)",
                value: "GRAM",
              },
            ]}
            async={true}
            placeholder="Select Measurement Unit"
            label="Measurement Unit"
            name={INVENTORY_FIELD_NAMES.measurementUnit}
            id={INVENTORY_FIELD_NAMES.measurementUnit}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            Icon={ScaleIcon}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            component={MultiSelectFormik}
            name={PRODUCT_FIELD_NAMES.vendorIdList}
            id={PRODUCT_FIELD_NAMES.vendorIdList}
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            data={vendorDataList || []}
            placeholder="Select Reserved Vendors"
            label="Select Reserved Vendors"
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            Icon={StorefrontIcon}
            async={true}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            multiline={true}
            minRows={2}
            maxRows={3}
            placeholder="Enter product description"
            label="Description"
            type="text"
            name={PRODUCT_FIELD_NAMES.description}
            id={PRODUCT_FIELD_NAMES.description}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
              minRows: 3,
            }}
            InputProps={FieldIcon(<LabelIcon />)}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default ProductFormUI;
