import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Checkbox, TextField } from "formik-mui";
import { Box } from "@mui/material";
import SelectField from "../../../components/Mui/Formik/SelectField";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SALON_ONBOARDING_ENDPOINT } from "../../../api/salon-api/salon-service";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { setDetails } from "../../../redux/feature/salonDetailsSlice";
import { TimePickerField } from "../../../components/Mui/Formik/DateSelectorFormik";
import { SALON_TYPE_DATA, STATE_DROPDOWN_DATA } from "./ConstData";
import dayjs from "dayjs";

function SalonDetailsForm({ handleNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const salonDetails = useSelector((state) => state.salonDetails);

  const [GSTFlag, setGSTFlag] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleOpen = (values) => {
    setOpen(true);
    setData({ ...values });
  };

  const handleClose = () => setOpen(false);

  function handleGSTFlag(event) {
    const isChecked = event.target.checked;
    setGSTFlag(isChecked);
    if (!isChecked) {
      setGstNumber(""); // Clear GST number when unchecked
    }
  }

  async function handleSubmit(values) {
    let formattedValues = {
      ...values,
      salonOpeningTime: dayjs(values.salonOpeningTime).format("HH:mm:ss"),
      salonClosingTime: dayjs(values.salonClosingTime).format("HH:mm:ss"),
      gstFlag: GSTFlag,
      gstNumber: GSTFlag ? gstNumber : "", // Send empty string if GSTFlag is false
    };


    try {
      const response = await axiosPrivate({
        method: "POST",
        url: SALON_ONBOARDING_ENDPOINT,
        data: formattedValues,
      });
      dispatch(setDetails(response.data));
      toast.success("Salon Onboarding Complete");
      window.localStorage.setItem("preSalonId", response?.data?.id)
      handleNext();
    } catch (error) {
      toast.error("Error Onboarding Salon");
    }
  }

  const initialValues = {
    regNo: "",
    name: "",
    salonOpeningTime: null,
    salonClosingTime: null,
    address: "",
    state: "",
    city: "",
    postalCode: "",
    salonManagerName: "",
    salonManagerPhoneNumber: "",
    salonManagerEmail: "",
    salonZonalManagerName: "",
    salonZonalManagerEmail: "",
    salonZonalManagerPhoneNumber: "",
    ownerName: "",
    multipleOutletInd: false,
    logoS3Path: "",
    status: 0,
    salonTypeStatus: 0,
    clientCredentials: "",
  };

  const validationSchema = yup.object({
    regNo: yup.string().required("Required"),
    name: yup.string().required("Required"),
    address: yup.string().required("Required"),
    state: yup.string().required("Required"),
    city: yup.string().required("Required"),
    postalCode: yup
      .string()
      .required("Required")
      .length(6, "Postal Code must be exactly 6 digits"),
    salonOpeningTime: yup.date().required("Required"),
    salonClosingTime: yup.date().required("Required"),
    salonManagerName: yup.string().required("Required"),
    salonManagerEmail: yup.string().email("Invalid email").required("Required"),
    salonManagerPhoneNumber: yup
      .string()
      .required("Required")
      .length(10, "Phone Number must be exactly 10 digits"),
    ownerName: yup.string().required("Required"),
    salonZonalManagerName: yup.string().required("Required"),
    salonZonalManagerPhoneNumber: yup
      .string()
      .required("Required")
      .length(10, "Phone Number must be exactly 10 digits"),
    salonZonalManagerEmail: yup
      .string()
      .email("Invalid email")
      .required("Required"),
  });

  const handleLogout = () => {
    window.localStorage.removeItem("auth")
    navigate("/superadmin")
  }

  return (
    <>
      <Box sx={{ position: "absolute", top: "0px", right: "0px" }}>
        <Button variant="contained" color="info" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <Typography component="h1" variant="body1" sx={{ textAlign: "center" }}>
        Please fill all the details for the Salon Onboarding
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOpen}
      >
        {({ errors, touched }) => (
          <Form>
            <Box component="div" noValidate sx={{ my: 3 }}>
              <Divider sx={{ my: 4 }}>
                <Chip label="Salon Details" size="small" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="regNo"
                    name="regNo"
                    label="Registration Number"
                    component={TextField}
                    helperText={touched.regNo ? errors.regNo : ""}
                    error={Boolean(touched.regNo && errors.regNo)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="name"
                    name="name"
                    label="Salon Name"
                    component={TextField}
                    helperText={touched.name ? errors.name : ""}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="address"
                    name="address"
                    label="Salon Address"
                    component={TextField}
                    helperText={touched.address ? errors.address : ""}
                    error={Boolean(touched.address && errors.address)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="state"
                    name="state"
                    label="State"
                    data={STATE_DROPDOWN_DATA}
                    component={SelectField}
                    helperText={touched.state ? errors.state : ""}
                    error={Boolean(touched.state && errors.state)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="city"
                    name="city"
                    label="City"
                    component={TextField}
                    helperText={touched.city ? errors.city : ""}
                    error={Boolean(touched.city && errors.city)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code"
                    type="text"
                    component={TextField}
                    helperText={touched.postalCode ? errors.postalCode : ""}
                    error={Boolean(touched.postalCode && errors.postalCode)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="clientCredentials"
                    name="clientCredentials"
                    label="Salon Password"
                    component={TextField}
                    helperText={
                      touched.clientCredentials ? errors.clientCredentials : ""
                    }
                    error={Boolean(
                      touched.clientCredentials && errors.clientCredentials
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={SelectField}
                    name="salonTypeStatus"
                    label="Salon Type"
                    data={SALON_TYPE_DATA}
                    helperText={
                      touched.salonTypeStatus ? errors.salonTypeStatus : ""
                    }
                    error={Boolean(
                      touched.salonTypeStatus && errors.salonTypeStatus
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    sx={{ width: "100%" }}
                    fullWidth
                    id="salonOpeningTime"
                    name="salonOpeningTime"
                    label="Salon Opening Time"
                    component={TimePickerField}
                    helperText={
                      touched.salonOpeningTime ? errors.salonOpeningTime : ""
                    }
                    error={Boolean(
                      touched.salonOpeningTime && errors.salonOpeningTime
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    sx={{ width: "100%" }}
                    id="salonClosingTime"
                    name="salonClosingTime"
                    label="Salon Closing Time"
                    component={TimePickerField}
                    helperText={
                      touched.salonClosingTime ? errors.salonClosingTime : ""
                    }
                    error={Boolean(
                      touched.salonClosingTime && errors.salonClosingTime
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Field
                    id="gstFlag"
                    type="checkbox"
                    label="GST Available"
                    checked={GSTFlag}
                    onChange={handleGSTFlag}
                    component={Checkbox}
                  />
                  <Typography>
                    GST (Please tick if GST is available in Salon)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="gstNumber"
                    name="gstNumber"
                    label="GST Number"
                    component={TextField}
                    disabled={!GSTFlag} // Disable when GSTFlag is false
                    onChange={(e) => setGstNumber(e.target.value)}
                    value={gstNumber}
                    helperText={touched.gstNumber ? errors.gstNumber : ""}
                    error={Boolean(touched.gstNumber && errors.gstNumber)}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 4 }}>
                <Chip label="Salon Manager Details" size="small" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="salonManagerName"
                    name="salonManagerName"
                    label="Salon Manager Name"
                    component={TextField}
                    helperText={
                      touched.salonManagerName ? errors.salonManagerName : ""
                    }
                    error={Boolean(
                      touched.salonManagerName && errors.salonManagerName
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="salonManagerPhoneNumber"
                    name="salonManagerPhoneNumber"
                    label="Salon Manager Phone Number"
                    component={TextField}
                    helperText={
                      touched.salonManagerPhoneNumber
                        ? errors.salonManagerPhoneNumber
                        : ""
                    }
                    error={Boolean(
                      touched.salonManagerPhoneNumber &&
                        errors.salonManagerPhoneNumber
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="salonManagerEmail"
                    name="salonManagerEmail"
                    label="Salon Manager Email"
                    component={TextField}
                    helperText={
                      touched.salonManagerEmail ? errors.salonManagerEmail : ""
                    }
                    error={Boolean(
                      touched.salonManagerEmail && errors.salonManagerEmail
                    )}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 4 }}>
                <Chip label="Salon Zonal Manager Details" size="small" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="salonZonalManagerName"
                    name="salonZonalManagerName"
                    label="Salon Zonal Manager Name"
                    component={TextField}
                    helperText={
                      touched.salonZonalManagerName
                        ? errors.salonZonalManagerName
                        : ""
                    }
                    error={Boolean(
                      touched.salonZonalManagerName &&
                        errors.salonZonalManagerName
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="salonZonalManagerPhoneNumber"
                    name="salonZonalManagerPhoneNumber"
                    label="Salon Zonal Manager Phone Number"
                    component={TextField}
                    helperText={
                      touched.salonZonalManagerPhoneNumber
                        ? errors.salonZonalManagerPhoneNumber
                        : ""
                    }
                    error={Boolean(
                      touched.salonZonalManagerPhoneNumber &&
                        errors.salonZonalManagerPhoneNumber
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="salonZonalManagerEmail"
                    name="salonZonalManagerEmail"
                    label="Salon Zonal Manager Email"
                    component={TextField}
                    helperText={
                      touched.salonZonalManagerEmail
                        ? errors.salonZonalManagerEmail
                        : ""
                    }
                    error={Boolean(
                      touched.salonZonalManagerEmail &&
                        errors.salonZonalManagerEmail
                    )}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 4 }}>
                <Chip label="Owner Details" size="small" />
              </Divider>
              <Grid container spacing={2}>
                {/* Other sections can be added here if needed */}
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="ownerName"
                    name="ownerName"
                    label="Owner Name"
                    component={TextField}
                    helperText={touched.ownerName ? errors.ownerName : ""}
                    error={Boolean(touched.ownerName && errors.ownerName)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ marginBottom: 4 }}
            >
              Create Salon
            </Button>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        data={data}
      />
    </>
  );
}

export function ConfirmationModal({ handleClose, open, handleSubmit, data }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirm Creating Salon?
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, marginBottom: 3 }}
        >
          Please review all details carefully before proceeding with the Salon
          creation.
        </Typography>
        <Button
          variant="contained"
          sx={{ marginRight: 2 }}
          onClick={() => {
            handleSubmit(data);
            handleClose(); // Close the modal after submission
          }}
        >
          Confirm
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default SalonDetailsForm;
