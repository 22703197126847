import { createSlice } from "@reduxjs/toolkit";


const initialSalonDetails = { Salon_data: [] }
export const SalonDetailsSlice = createSlice({
    name: "SalonDetails",
    initialState : initialSalonDetails,
    reducers : {
        setDetails: (state, action) => {
            state.Salon_data = {...state.Salon_data, ...action.payload}
        },
        removeAllDetails : (state, action) => {
            return initialSalonDetails
        }
    }
})


export const { setDetails, removeAllDetails } = SalonDetailsSlice.actions ;
export default SalonDetailsSlice.reducer ;