import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import useServiceCategory from "../../../../../hooks/api/useServiceCategory";
import { AddServiceCategoryMain } from "../ConfigureServices/ConfigServiceTable";
import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../../../redux/feature/slice";
import {
  IServiceCategory,
  IServiceCategoryData,
} from "../ConfigureServices/types/configService";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import {
  usePaginatedServicesCategory,
  useUpdateServicesCategory,
} from "./configServiceCategoryApiHook";
import EnhancedTableHead from "../../../../../components/Mui/table/Table";
import { headCellsServiceCategoryConfig } from "./serviceCategoryConstant";
import { truncateText } from "../../../../../helper/Common/text";
import { SERVICE_CATEGORY_ENDPOINT } from "../../../../../api/salon-api/salon-service";
import { setDeleteServiceCategory } from "../../../../../redux/feature/salonServiceSlice";

export const SERVICE_CATEGORY_FORM_MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export type IDialogueModeServiceCategory = {
  mode: string;
  data: IServiceCategory;
};

const ServiceCatTable = () => {
  const { serviceListData } = useServiceCategory();
  const [openDialogueAddServiceCategory, setOpenDialogueAddServiceCategory] = useState(false);
  const [openDialogueEditServiceCategory, setOpenDialogueEditServiceCategory] = useState<IDialogueModeServiceCategory>({
    mode: SERVICE_CATEGORY_FORM_MODE.ADD,
    data: {} as IServiceCategory,
  });

  // Table's state
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected] = useState([]);
  const serviceData = useSelector((state: RState) => state?.services?.serviceCategories) as IServiceCategoryData;
  const serviceDataContent = serviceData?.content || [];
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { updateServiceCategory } = useUpdateServicesCategory();

  const {
    isLoading,
    currentPage: page,
    pageSize: rowsPerPage,
    setPage,
    setSize: setRowsPerPage,
  } = usePaginatedServicesCategory(1, 5);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading && serviceDataContent) {
      setDataLoaded(true);
    }
  }, [isLoading, serviceDataContent]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseDialogueAddServiceCategory = () => {
    setOpenDialogueAddServiceCategory(false);
    setOpenDialogueEditServiceCategory({
      mode: SERVICE_CATEGORY_FORM_MODE.ADD,
      data: {} as IServiceCategory,
    });
  };

  const handleOpenDialogueAddServiceCategory = () => {
    setOpenDialogueAddServiceCategory(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    // if (newPage < 1) return;
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: IServiceCategory
  ) => {
    const checked = event.target.checked;
    const requestBody = {
      categoryName: row.categoryName,
      description: row.description,
      gender: row.gender,
      id: row.id,
      status: checked ? "ACTIVE" : "INACTIVE",
    } as IServiceCategory;
    try {
      updateServiceCategory(requestBody);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleOpenDialogueEditServiceCategory = (row: IServiceCategory) => {
    setOpenDialogueEditServiceCategory({
      mode: SERVICE_CATEGORY_FORM_MODE.EDIT,
      data: row,
    });
    handleOpenDialogueAddServiceCategory();
  };

  const handleDeleteService = async (row: IServiceCategory) => {
    try {
      await axiosPrivate.delete<IServiceCategory>(
        `${SERVICE_CATEGORY_ENDPOINT}/${row.id}`
      );
      dispatch(setDeleteServiceCategory(row.id));
    } catch (error: any) {
      console.error("error", error);
    }
  };

  const tableInclose = (
    <Table>
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={serviceDataContent.length}
        headCells={headCellsServiceCategoryConfig}
      />
      <TableBody>
        {serviceDataContent?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row: IServiceCategory, index: number) => {
          const labelId = `service-table-${index}`;

          return (
            <TableRow
              key={labelId}
              sx={{
                bgcolor: row.status === "DELETED" ? "gray" : "white",
                cursor: "pointer",
                mx: 4,
              }}
              role="checkbox"
              tabIndex={-1}
            >
              <TableCell component="th" id={labelId} scope="row">
                {row?.categoryName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.gender}
              </TableCell>

              <TableCell component="th" scope="row">
                <Switch
                  checked={row?.status === "ACTIVE"}
                  onChange={(event) => handleChangeSwitch(event, row)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Tooltip title={row?.description}>
                  <Stack>{truncateText(row?.description, 15, "...")}</Stack>
                </Tooltip>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
              >
                <Stack direction="row">
                  <ModeEditIcon
                    sx={{ mx: 1 }}
                    onClick={() => handleOpenDialogueEditServiceCategory(row)}
                  />
                  {/* <DeleteIcon
                    color="error"
                    sx={{ mx: 1 }}
                    onClick={() => handleDeleteService(row)}
                  /> */}
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Stack width={"100%"}>
      <TableContainer>
        <Toolbar>
          <Grid container spacing={2} alignItems="center" width={"45%"}>
            <Grid item>
              <SearchIcon color="inherit" style={{ display: "block" }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by Service Name"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "default" },
                }}
                variant="standard"
                size="small"
              />
            </Grid>
            <Grid item xs>
              <Chip
                label="Search"
                onClick={() => {
                  console.log("search");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" width={"55%"}>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleOpenDialogueAddServiceCategory}
              >
                Add Service Category
              </Button>
            </Grid>
          </Grid>
        </Toolbar>

        {!dataLoaded ? (
          <LinearProgress />
        ) : serviceDataContent.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ my: 2 }}>
            No data available
          </Typography>
        ) : (
          tableInclose
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={serviceData?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page- 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <AddServiceCategoryMain
        handleDialogueClose={handleCloseDialogueAddServiceCategory}
        openDialogue={openDialogueAddServiceCategory}
        openDialogueEditServiceCategory={openDialogueEditServiceCategory}
      />
    </Stack>
  );
};

export default ServiceCatTable;
